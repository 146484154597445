import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { TextField, Autocomplete } from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FaWindowClose } from 'react-icons/fa';


function EditRequisition(props) {
    const [loading,setLoading] = useState(true)
    const [type,setType] = useState('direct')
    const [open,setOpen] = useState(false)
    const [found,setFound] = useState(false)
    const [sl,setSl] = useState(null)
    const cookies = new Cookies()
    const ntoken = cookies.get('access')

    const familyids = [70,72,55,56,57,59]
    const itemOption = useRef()
    const editrow = useRef(props.editRow)
    const [selectValue,setSelectValue] = useState(
        {
          "id":"",
          "type":type,
          "itemcategory":"",
          "itemfamily":"",
          "itemtype":"",
          "subtype1":"",
          "subtype2":"",
          "mtofconst":"",
          "materialg":"",
          "specification":"",
          "process":"",
          "stage":"",
          "supplytype":"",
          "itemmake":"",
          "techspec":"",
          "dimension":"",
          "otherdetails":"",
          "stockingtype":"",
          "glclass":"",
          "uom":"",
          "linetype":"",
          'hsn':'',
          "commodity":"",
          "subcommodity":"",
          "slno":"",
          "itemno":'',
          "description":""
        }
      )
        const handleRadioButton = (event)=>{
        var radiovalue = event.target.value;
        setType(radiovalue)
        setSelectValue({
          ...selectValue,
          type:radiovalue
        })
       
    
    }
    function getindirectItemRecord(formdata){
        try{
          const res = axios.put("/reqrecord/",{formdata}, {
            headers: {
                'Authorization': `Bearer ${ntoken}`
            },
            withCredentials: true
        });
        res
        .then((response)=>{
          console.log(response)
          if(response.status === 200){
            setSl(response.data.record[0].indirectitemid)
            return response.data.record[0].indirectitemid
          }
        })
        .catch((error)=>{
          console.log(error)
        })
     
      }
      catch(error){
        console.log(error)
      }
      }
    function getItemRecord(formdata){
        try{
          const res = axios.post("/reqrecord/",{formdata}, {
            headers: {
                'Authorization': `Bearer ${ntoken}`
            },
            withCredentials: true
        });
        res
        .then((response)=>{
          console.log(response)
          if(response.status === 200){
            setSl(response.data.record[0].directitemid)
            return response.data.record[0].directitemid
          }
        })
        .catch((error)=>{
          console.log(error)
        })
     
        }
        catch(error){
          console.log(error)
        }
      }
    
     
          
        
       
    function splitString(str, chunkSize) {
        let result = [];
        for (let i = 0; i < str.length; i += chunkSize) {
            result.push(str.slice(i, i + chunkSize));
        }
        return result;
    }
    function validateField(obj){
      let indirectFields = [
        'itemcategory',
        'itemfamily',
        'itemtypes',
      ]
      let directFields = [
        'itemcategory',
        'itemfamily',
        'itemtypes',
        'moc',
      ]
      
      let requiredFields = ""
      if(type==='direct'){
        requiredFields =directFields;
      }
      else{
        requiredFields = indirectFields;
      }
      for (const field of requiredFields) {
       
        if (obj[field] === null || obj[field] === '') {
          toast.warning(`Field ${field} is required`)
          return false; // Field is null or blank
        }
       
       
      }
    
      return true; // All fields are valid
    }
    function validateDescriptionFromJD(itemcode,description){
        let rbtoken = cookies.get('rsbtoken')
        console.log("rsbtoken",rbtoken)
        let payload = JSON.stringify({ 
          "token":rbtoken, 
          "DataInfo": "ITM", 
          "DataValue1":itemcode, 
          "DataValue2": description 
        })
     
  
        if(rbtoken){
          const res = axios.post("http://192.168.50.158:8073/jderest/orchestrator/IC_DatainfoValidate",payload, {
            headers: { 
              'Content-Type': 'application/json', 
              'Access-Control-Allow-Origin':'*'
          },
          withCredentials: false
          });
          res
          .then((response)=>{
        //     if(res['cReturnFlag']=="0"):
        //      setFound(false)
        //     return Response({"exists":f"Description found as {res['szRemarks']}"})
        // if(res['cReturnFlag']=="1"):
            console.log("jd description  ",response)
            const rec_response = response
  
            if(rec_response.status === 200){
              if(rec_response.data['cReturnFlag']==="0"){
                toast.warning("Description found")
                setFound(true)
                return true
              }
                   
              else if(rec_response.data['cReturnFlag']==="1"){
                toast.success("Description Not found")
                setFound(false)
                return false
              }
              
            }
          
          })
          .catch((error)=>{
            console.log(error)
          })
        }
        else{
        toast.warning("Please generate a token")
        }
        
  
      }

    function ValidateDescription(itemcode,description){
    
        const res = axios.post("/validatedesc/",{itemcode,description}, {
          headers: {
              'Authorization': `Bearer ${ntoken}`
          },
          withCredentials: true
      });
      res
      .then((response)=>{
        const rec_response = response
        if(rec_response.status === 200){
          if(rec_response.data.exists){
            toast.warning(rec_response.data.exists)
            setFound(true)
          }
          else if(rec_response.data.not_exists){
            let valid = validateDescriptionFromJD(itemcode,description)
            return valid
           
          }
          
        }
    
      })
      .catch((error)=>{
        console.log(error)
      })
      
      
    }
    function generatecode(){
    
        let vfield = validateField(selectValue)
        function generate(){
          const categ = itemOption.current.item_categories.find(element=>element.id===selectValue.itemcategory)
          const fam = itemOption.current.families.find(element=>element.id===selectValue.itemfamily)
          const itemtp = itemOption.current.item_types.find(element=>element.id===selectValue.itemtype)
          const moc_code =itemOption.current.metals_of_construction.find(element=>element.id===selectValue.mtofconst)
          const subtype1 = itemOption.current.subtype1.find((element)=>element.id===selectValue.subtype1)
          const subtype2 = itemOption.current.subtype2.find((element)=>element.id===selectValue.subtype2)
          const specification = itemOption.current.specification.find((element)=>element.id===selectValue.specification)
          const itemc = selectValue.itemcategory
          const itemmoc =  selectValue.mtofconst
          const itemf = selectValue.itemfamily
          const itemt = selectValue.itemtype 
          const supplyt = itemOption.current.supply_types.find(element=>element.id===selectValue.supplytype)
          var prev_Item_code = ""
          var curnt_Item_code = ""
          var slno = ""
          var item_code = ""
          var descrptn = ""
          var prev_descr = props.editRow.description
         
          if(type === "indirect"){
             prev_Item_code = props.editRow.itemno
             curnt_Item_code = categ.assignedNo + fam.assignedNo + itemtp.assignedNo + (Number(props.editRow.slno)).toString().padStart(4, '0')
            if(prev_Item_code === curnt_Item_code){
                item_code = prev_Item_code
                slno = Number(props.editRow.slno)
            }
            else{
                console.log("before item code sl is",sl)
                getindirectItemRecord({itemc,itemf,itemt})
                item_code = categ.assignedNo + fam.assignedNo + itemtp.assignedNo + (Number(sl) + 1).toString().padStart(4, '0')
                slno = (Number(sl) + 1);
            }

            descrptn = (subtype1?.name??"") + " " + (itemtp?.name??"") + " " + (specification?.name??"")
         
            
            
    
          }
          else{
            debugger
            prev_Item_code = props.editRow.itemno
            curnt_Item_code = categ?.assignedNo + moc_code?.assignedNo + fam?.assignedNo + itemtp?.assignedNo  + (Number(props.editRow.slno)).toString().padStart(4, '0') + supplyt.assignedNo
            console.log("Previous Item code",prev_Item_code,curnt_Item_code)
            if(prev_Item_code === curnt_Item_code){
                item_code = prev_Item_code
                slno = Number(props.editRow.slno)
            }
            else{
                getItemRecord({itemc,itemmoc,itemf,itemt})
                item_code = categ?.assignedNo + moc_code?.assignedNo + fam?.assignedNo + itemtp?.assignedNo  + (Number(sl) + 1).toString().padStart(4, '0') + supplyt.assignedNo
                slno = (Number(sl) + 1);
            }
            descrptn = (subtype1?.name??"") + " " + (itemtp?.name??"") + " " + (subtype2?.name??"") 
           
          }
          // Description not validating from esolution because 
          //it may always exist prev description in database
          const splited_desc = splitString(descrptn,30)
          if(prev_descr===descrptn){
            console.log("changed description",prev_descr,descrptn)
            console.log("Description not changed ")
            toast.warning("Description not Changed.")
          }
          else{
            console.log("Description changed ")
            toast.warning("Description Changed. Validating again ....")
            ValidateDescription(item_code,descrptn)
          }
         
          
      
        
        console.log("sl is ",slno)
          setSelectValue({
            ...selectValue,
            "slno":slno,
            'itemno':item_code,
            "splited_desc":splited_desc,
            "description":descrptn
          })
          setOpen(true)
    
    
        }
        function restrictEdit(){
          setFound(false)
          setOpen(true)
          setSelectValue({
            ...selectValue,
            "type":editrow.current.type,
            "itemcategory":editrow.current.itemcategory?.id,
            "itemfamily":editrow.current.itemfamily?.id,
            "itemtype":editrow.current.itemtype?.id,
            "subtype1":editrow.current.subtype1?.id,
            "subtype2":editrow.current.subtype2?.id,
            "specification":editrow.current.specification?.id,
            "slno":editrow.current.slno,
            'itemno':editrow.current.itemno,
            "splited_desc":splitString(editrow.current.description,30),
            "description":editrow.current.description
          })
        }
        if(props.editRow.adminrejected === true || props.editRow.orejected === true || props.editRow.arejected){
          restrictEdit()
        }
        else{
          vfield?generate()
          : console.log(false)
        }
        
       
    
    }
    async function CreateRequisition(){
        try{
            console.log("Data to save is",selectValue)
            let res = await axios.put("/requisition/"+editrow.current.id+"/",{selectValue}, {
              headers: {
                  'Authorization': `Bearer ${ntoken}`
              },
              withCredentials: true
            });
          
          
            const rec_response = res
            if(rec_response.status === 200){
            if(rec_response.data.exists){
                toast.warning(rec_response.data.exists)
                setFound(true)
            }
            else if(rec_response.data.not_exists){
                toast.warning(rec_response.data.not_exists)
                setFound(false)
            }
            else if(rec_response.data.valid){
                toast.success(rec_response.data.valid)
                setOpen(false)
            }
            else if(rec_response.data.not_valid){
                toast.warning(rec_response.data.not_valid)
            }
            return rec_response.data
            }
        }
      catch(error){
        console.log(error)
      }
    }
    
    useEffect(()=>{
        let isMounted = true
          async function MasterItem(){
              const cookies = new Cookies()
              const token = cookies.get('access')
          try{
              const res =  axios.get("/masteritem/", {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                withCredentials: true
            });
            res
            .then((response)=>{
  
             if(response.status === 200 && isMounted===true){
              const item = response.data?response.data:null
              console.log("items are",item)
              itemOption.current = item
              setSelectValue({
                id:editrow.current.id,
                type:editrow.current.type,
                itemcategory:editrow.current.itemcategory?.id,
                itemfamily:editrow.current.itemfamily?.id,
                itemtype:editrow.current.itemtype?.id,
                subtype1:editrow.current.subtype1?.id,
                subtype2:editrow.current.subtype2?.id,
                mtofconst:editrow.current.mtofconst?.id,
                materialg:editrow.current.materialg?.id,
                itemmake:editrow.current.itemmake?.id,
                specification:editrow.current.specification?.id,
                dimension:editrow.current.dimension?.id,
                otherdetails:editrow.current.otherdetails?.id,
                process:editrow.current.process?.id,
                stage:editrow.current.stage?.id,
                supplytype:editrow.current.supplytype?.id,
                stockingtype:editrow.current.stockingtype?.id,
                glclass:editrow.current.glclass?.id,
                uom:editrow.current.uom?.id,
                linetype:editrow.current.linetype?.id,
                hsn:editrow.current.hsn?.id,
                commodity:editrow.current.commodity?.id,
                subcommodity:editrow.current.subcommodity?.id,
                slno:editrow.current.slno,
                itemno:editrow.current.itemcode,
                description:editrow.current.description,
              })
              setLoading(false)
             }
              
            })
            .catch((error)=>{
              console.error("catched error",error)
              if(error.response.status === 401 && isMounted === true){
                  toast.warning("Session expired")
          
              }
              isMounted = false
            })
           
            
            }
            catch(error){
              console.log(error)
              toast.warning("session timeout")
       
            }
          }
          MasterItem()
          return () => {
            isMounted = false; // Cleanup flag on unmount
          };
      },[])
  return (
    <div>
    <Dialog onClose={()=>setOpen(false)} open={open}>
      <DialogTitle>Item Code and Desctipion</DialogTitle>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Item Code</TableCell>
            {selectValue.splited_desc?selectValue.splited_desc.map((element,index)=><TableCell key={index} align="right">Description {index + 1}</TableCell>):""}
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow
              key={selectValue.itemno}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {selectValue.itemno}
              </TableCell>
              {selectValue.splited_desc?selectValue.splited_desc.map((element,index)=>  <TableCell key={index} component="th" scope="row">
                {element}
              </TableCell>):""}
            </TableRow>
          
        </TableBody>
      </Table>
    </TableContainer>
    {found?"":
      <Button variant='contained' onClick={CreateRequisition}>Save</Button>
    }
    </Dialog>
    {   loading?<h3>Loading ......</h3>: 
        <form className="p-2">
          <FaWindowClose className='float-end text-xl mr-2' onClick={props.toggleDrawer(false)} />
        <FormControl >
                
                <RadioGroup
                row
                name="type"
                value={type}    
                onChange={handleRadioButton} 
                >
                    <FormControlLabel value="direct" control={<Radio />} label="Direct" />
                    <FormControlLabel value="indirect" control={<Radio />} label="Indirect" />
                </RadioGroup>
            </FormControl>
              <Box
                
                sx={{
                    '& > :not(style)': { m: 1,gap:'5'
                     },
                     '& .MuiInputBase-root': { 
                                   fontSize: '0.85rem' // Decrease font size for TextField
                    }
                }
                
                }
                noValidate
                autoComplete="off"
                >
                <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="itemcategory_autocomplete"
                      options={itemOption.current.item_categories.filter((current)=>current.type===type)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.item_categories.find((item) => item.id === selectValue.itemcategory) || null} // determine the selected value
                      onChange={(event, newValue) => {
                      if(newValue ===null){
                        setSelectValue({ ...selectValue, itemcategory: newValue ? newValue.id : '' });
                      }
                      else{
                       
                    
                        setSelectValue({ ...selectValue, itemcategory: newValue ? newValue.id : '' });
                   
                      }
                      
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Item Category"  />
                      )}
                    />
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="itemfamily_autocomplete"
                      options={itemOption.current.families.filter(element=>element.type === type && element.category === selectValue.itemcategory)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.families.find((item) => item.id === selectValue.itemfamily) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        if(newValue === null){
                        setSelectValue({ ...selectValue, itemfamily: newValue ? newValue.id : '' });

                        }
                        else{
                        
                        
                        setSelectValue({ ...selectValue, itemfamily: newValue ? newValue.id : '' });
                        }
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Item Family" />
                      )}
                    />
                  
                  
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="itemtypes_autocomplete"
                      options={itemOption.current.item_types.filter(element=>element.type === type && element.family === selectValue.itemfamily)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.item_types.find((item) => item.id === selectValue.itemtype) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        console.log(newValue)
                        setSelectValue({ ...selectValue, itemtype: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Item Types" />
                      )}
                    />
                  
                 
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="subtype1_autocomplete"
                      options={itemOption.current.subtype1.filter(element=>element.type === type && element.itemtype === selectValue.itemtype)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.subtype1.find((item) => item.id === selectValue.subtype1) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        console.log(newValue)
                        setSelectValue({ ...selectValue, subtype1: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Sub Type1" />
                      )}
                    />
                 
    
                  {
                    type === 'direct'? 
                    <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="subtype2_autocomplete"
                      options={itemOption.current.subtype2.filter(element=>element.type === type && element.itemtype === selectValue.itemtype)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.subtype2.find((item) => item.id === selectValue.subtype2) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, subtype2: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Sub Type2" />
                      )}
                    />
                     
                    :""
                }
                {
                  type === "direct"?
                  <div>
                  <Autocomplete
                    disablePortal
                    size="small"
                    sx={{marginTop:"10px"}}
                    classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm '}}
                    id="mtgrade_autocomplete"
                    options={itemOption.current.materialgrade} // the options prop expects an array of objects
                    getOptionLabel={(option) => option.name} // function to display option label
                    value={itemOption.current.materialgrade.find((item) => item.id === selectValue.materialg) || null} // determine the selected value
                    onChange={(event, newValue) => {
                      setSelectValue({ ...selectValue, materialg: newValue ? newValue.id : '' });
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                    renderInput={(params) => (
                      <TextField {...params} label="Meterial Grade" />
                    )}
                  />
                  <Autocomplete
                  className='mt-2'
                    disablePortal
                    size="small"
                    classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                    id="moc_autocomplete"
                    options={itemOption.current.metals_of_construction.filter(element=>element.type === type && element.category === selectValue.itemcategory)} // the options prop expects an array of objects
                    getOptionLabel={(option) => option.name} // function to display option label
                    value={itemOption.current.metals_of_construction.find((item) => item.id === selectValue.mtofconst) || null} // determine the selected value
                    onChange={(event, newValue) => {
                      
                      setSelectValue({ ...selectValue, mtofconst: newValue ? newValue.id : '' });
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                    renderInput={(params) => (
                      <TextField {...params} label="Meterial of construction" />
                    )}
                  />
               
                  </div>
                  :""
                  }
                    {
                      type === 'indirect'?
                      <Autocomplete
                        disablePortal
                        size="small"
                        sx={{marginTop:"10px"}}
                        classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm '}}
                        id="itemmake_autocomplete"
                        options={itemOption.current.itemmake} // the options prop expects an array of objects
                        getOptionLabel={(option) => option} // function to display option label
                        value={itemOption.current.itemmake.find((item) => item.id === selectValue.itemmake) || null} // determine the selected value
                        onChange={(event, newValue) => {
                          setSelectValue({ ...selectValue, itemmake: newValue ? newValue.id : '' });
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            )}
                        renderInput={(params) => (
                          <TextField {...params} label="Item Make" />
                        )}
                      />
                      :""
                    }
                    {
                      selectValue.itemfamily === 63?
                      <Autocomplete
                        disablePortal
                        size="small"
                        sx={{marginTop:"10px"}}
                        classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm '}}
                        id="specification_autocomplete"
                        options={itemOption.current.specification.filter(element=>element.type === type)} // the options prop expects an array of objects
                        getOptionLabel={(option) => option} // function to display option label
                        value={itemOption.current.specification.find((item) => item.id === selectValue.specification) || null} // determine the selected value
                        onChange={(event, newValue) => {
                          setSelectValue({ ...selectValue, specification: newValue ? newValue.id : '' });
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            )}
                        renderInput={(params) => (
                          <TextField {...params} label="Item Make" />
                        )}
                      />
                      :""
                    }
                  {
                    familyids.includes(selectValue.itemfamily)?
                      <div>
                      <Autocomplete
                        disablePortal
                        size="small"
                        sx={{marginTop:"10px"}}
                        classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm '}}
                        id="dimensions_autocomplete"
                        options={itemOption.current.dimensions.filter(element=>element.type === type)} // the options prop expects an array of objects
                        getOptionLabel={(option) => option.name} // function to display option label
                        value={itemOption.current.dimensions.find((item) => item.id === selectValue.dimension) || null} // determine the selected value
                        onChange={(event, newValue) => {
                          setSelectValue({ ...selectValue, dimension: newValue ? newValue.id : '' });
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            )}
                        renderInput={(params) => (
                          <TextField {...params} label="Dimension" />
                        )}
                      />
                       <Autocomplete
                        disablePortal
                        size="small"
                        sx={{marginTop:"10px"}}
                        classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm '}}
                        id="otherdetails_autocomplete"
                        options={itemOption.current.otherdetails} // the options prop expects an array of objects
                        getOptionLabel={(option) => option.name} // function to display option label
                        value={itemOption.current.otherdetails.find((item) => item.id === selectValue.otherdetails) || null} // determine the selected value
                        onChange={(event, newValue) => {
                          setSelectValue({ ...selectValue, otherdetails: newValue ? newValue.id : '' });
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            )}
                        renderInput={(params) => (
                          <TextField {...params} label="Other Details" />
                        )}
                      />
                      </div>
                      
                      :""
                    }
                  {
                    selectValue.itemcategory === 2?
                    <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="lastprocess_autocomplete"
                      options={itemOption.current.processes.filter(element=>element.type === type)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.processes.find((item) => item.id === selectValue.process) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, process: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} className='text-sm' label="Last Process" />
                      )}
                    />
                 :""
                  }
                  {
                    selectValue.itemcategory === 3?
                    <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="stages_autocomplete"
                      options={itemOption.current.stages.filter(element=>element.type === type)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.stages.find((item) => item.id === selectValue.stage) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, stage: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Stages" />
                      )}
                    />
                     
                    :""
                  }
                  {
                    type === "direct"?
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="supplytype_autocomplete"
                      options={itemOption.current.supply_types.filter(element=>element.type === type)} // the options prop expects an array of objects
                      getOptionLabel={(option) => option.name} // function to display option label
                      value={itemOption.current.supply_types.find((item) => item.id === selectValue.supplytype) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, supplytype: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Supply Type" />
                      )}
                    />
                    :""
                    }
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="stockingtype_autocomplete"
                      options={itemOption.current.stocking_types} // the options prop expects an array of objects
                      getOptionLabel={(option) => `${option.name} (${option.assignedNo})`} // function to display option label
                      value={itemOption.current.stocking_types.find((item) => item.id === selectValue.stockingtype) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, stockingtype: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name} ({option.assignedNo})
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Stocking Type" />
                      )}
                    />
                     <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="glclass_autocomplete"
                      options={itemOption.current.gl_classes} // the options prop expects an array of objects
                      getOptionLabel={(option) => `${option.assignedNo}`} // function to display option label
                      value={itemOption.current.gl_classes.find((item) => item.id === selectValue.glclass) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, glclass: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.assignedNo}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Gl Class" />
                      )}
                    />
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="uom_autocomplete"
                      options={itemOption.current.uoms} // the options prop expects an array of objects
                      getOptionLabel={(option) => `${option.shortform}`} // function to display option label
                      value={itemOption.current.uoms.find((item) => item.id === selectValue.uom) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, uom: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.shortform}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="UOM" />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="hsn_autocomplete"
                      options={itemOption.current.hsn} // the options prop expects an array of objects
                      getOptionLabel={(option) => `${option.name}`} // function to display option label
                      value={itemOption.current.hsn.find((item) => item.id === selectValue.hsn) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, hsn: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="HSN" />
                      )}
                    />
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="linetype_autocomplete"
                      options={itemOption.current.line_types} // the options prop expects an array of objects
                      getOptionLabel={(option) => `${option.name} (${option.shortform})`} // function to display option label
                      value={itemOption.current.line_types.find((item) => item.id === selectValue.linetype) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, linetype: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name} ({option.shortform})
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Line Type" />
                      )}
                    />
                  
                  <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="commodity_autocomplete"
                      options={itemOption.current.commodity} // the options prop expects an array of objects
                      getOptionLabel={(option) => `${option.name}`} // function to display option label
                      value={itemOption.current.commodity.find((item) => item.id === selectValue.commodity) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, commodity: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Commodity Class" />
                      )}
                    />
                 <Autocomplete
                      disablePortal
                      size="small"
                      classes={{ input: 'autoComplete-text', option: 'autoComplete-text text-sm'}}
                      id="subCommodity_autocomplete"
                      options={itemOption.current.subcommodity} // the options prop expects an array of objects
                      getOptionLabel={(option) => `${option.name}`} // function to display option label
                      value={itemOption.current.subcommodity.find((item) => item.id === selectValue.subcommodity) || null} // determine the selected value
                      onChange={(event, newValue) => {
                        
                        setSelectValue({ ...selectValue, subcommodity: newValue ? newValue.id : '' });
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                      renderInput={(params) => (
                        <TextField {...params} label="Commodity Sub Class" />
                      )}
                    />
                      <Button variant="contained" onClick={generatecode} >Generate</Button>
                 
             
              
                
              </Box>
      
        </form>
        }
    </div>
  )
}

export default EditRequisition